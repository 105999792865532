import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import apiService from "../../services/ApiService";
import { toast } from "react-hot-toast";
import IconButton from "@mui/material/IconButton";
import { Link } from "react-router-dom";
import AddIcon from "@mui/icons-material/AddBox";
import Switch from "@mui/material/Switch";
import { useContext } from "react";
import { AuthContext } from "context/AuthContext";

function PendingUsers() {
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  const { user } = useContext(AuthContext); // Get the current user from context

  // Fetch Pending Users
  useEffect(() => {
    const fetchPendingUsers = async () => {
      const departmentId = user?.department?._id;
      const userId = user?._id;

      if (!departmentId || !userId) return; // Exit if departmentId or userId is not available

      try {
        const response = await apiService.post("/api/v1/users/pendingUsersList", {
          departmentId,
          userId,
        });

        if (response.status === 200) {
          const pendingUsers = response.data.users;
          setColumns([
            { Header: "First Name", accessor: "firstName", align: "left" },
            { Header: "Second Name", accessor: "secondName", align: "left" },
            { Header: "Employee No.", accessor: "employeeNo", align: "left" },
            { Header: "Company", accessor: "company", align: "left" },
            { Header: "Business Unit", accessor: "businessUnit", align: "left" },
            { Header: "Department", accessor: "department", align: "left" },
            { Header: "Role", accessor: "role", align: "left" },
            { Header: "Approved", accessor: "isApproved", align: "center" },
          ]);

          const formattedRows = pendingUsers.map((user) => ({
            id: user._id,
            firstName: (
              <MDTypography display="block" variant="button" fontWeight="medium">
                {user.firstName}
              </MDTypography>
            ),
            secondName: (
              <MDTypography variant="caption" color="text" fontWeight="medium">
                {user.secondName}
              </MDTypography>
            ),
            employeeNo: (
              <MDTypography variant="caption" color="text" fontWeight="medium">
                {user.employeeNo}
              </MDTypography>
            ),
            company: (
              <MDTypography variant="caption" color="text" fontWeight="medium">
                {user.company?.name || "N/A"}
              </MDTypography>
            ),
            businessUnit: (
              <MDTypography variant="caption" color="text" fontWeight="medium">
                {user.businessUnit?.name || "N/A"}
              </MDTypography>
            ),
            department: (
              <MDTypography variant="caption" color="text" fontWeight="medium">
                {user.department?.name || "N/A"}
              </MDTypography>
            ),
            role: (
              <MDTypography variant="caption" color="text" fontWeight="medium">
                {user.roles.map((el) => el?.name) || "N/A"}
              </MDTypography>
            ),
            isApproved: (
              <Switch
                checked={user.isApproved}
                onChange={() => handleToggleApproval(user._id, !user.isApproved)}
                color="primary"
              />
            ),
          }));

          setRows(formattedRows);
        }
      } catch (error) {
        toast.error("Failed to fetch pending users");
      }
    };

    fetchPendingUsers();
  }, []);

  // Handle approval toggle
  const handleToggleApproval = async (userId, newStatus) => {
    try {
      const response = await apiService.put(`/api/v1/users/update/${userId}`, {
        isApproved: newStatus,
      });
      if (response.status === 200) {
        toast.success("User approval status updated successfully");
        setRows((prevRows) =>
          prevRows.map((row) => {
            if (row.id === userId) {
              return {
                ...row,
                isApproved: (
                  <Switch
                    checked={newStatus}
                    onChange={() => handleToggleApproval(userId, !newStatus)}
                    color="primary"
                  />
                ),
              };
            }
            return row;
          })
        );
      }
    } catch (error) {
      toast.error("Failed to update user approval status");
    }
  };

  // Handle delete
  const handleDelete = async (userId) => {
    try {
      const response = await apiService.delete(`/api/v1/users/delete/${userId}`);
      if (response.status === 200) {
        toast.success("User deleted successfully");
        setRows((prevRows) => prevRows.filter((row) => row.id !== userId));
      }
    } catch (error) {
      toast.error("Failed to delete user");
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <MDTypography variant="h6" color="white">
                  Pending Users
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  table={{ columns, rows }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default PendingUsers;
