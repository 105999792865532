import { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import apiService from "../../services/ApiService";
import { InputLabel } from "@mui/material";
import { useNavigate } from "react-router-dom";

function Create() {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: "",
    description: "",
    legalEntityNumber: "",
    code: "",
    companyId: "",
  });

  const [companies, setCompanies] = useState([]);

  useEffect(() => {
    const fetchCompanies = async () => {
      try {
        const response = await apiService.get("/api/v1/companies/list");
        setCompanies(response?.data?.companies);
      } catch (error) {
        console.error("Error fetching companies:", error);
      }
    };

    fetchCompanies();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await apiService.post("/api/v1/businessUnits/create", formData);
      if (response.status === 200) {
        navigate("/business-units");
        console.log("Business Unit created:", response.data);
      }
    } catch (error) {
      console.error("Error creating business unit:", error);
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <MDTypography variant="h6" color="white">
                  Create Business Unit
                </MDTypography>
              </MDBox>
              <MDBox pt={3} px={2} component="form" onSubmit={handleSubmit}>
                <MDBox mb={2}>
                  <MDInput
                    type="text"
                    label="Business Unit Name *"
                    name="name"
                    variant="standard"
                    fullWidth
                    value={formData.name}
                    onChange={handleInputChange}
                  />
                </MDBox>
                <MDBox mb={2}>
                  <MDInput
                    type="text"
                    label="Description"
                    name="description"
                    variant="standard"
                    fullWidth
                    value={formData.description}
                    onChange={handleInputChange}
                  />
                </MDBox>
                <MDBox mb={2}>
                  <MDInput
                    type="text"
                    label="Legal Entity Number"
                    name="legalEntityNumber"
                    variant="standard"
                    fullWidth
                    value={formData.legalEntityNumber}
                    onChange={handleInputChange}
                  />
                </MDBox>
                <MDBox mb={2}>
                  <MDInput
                    type="text"
                    label="Code *"
                    name="code"
                    variant="standard"
                    fullWidth
                    value={formData.code}
                    onChange={handleInputChange}
                  />
                </MDBox>
                <MDBox mb={2}>
                  <InputLabel id="company-label">Select Company *</InputLabel>
                  <Select
                    labelId="company-label"
                    name="companyId"
                    value={formData.companyId}
                    onChange={handleInputChange}
                    fullWidth
                    variant="standard"
                  >
                    {companies.map((company) => (
                      <MenuItem key={company._id} value={company._id}>
                        {company.name}
                      </MenuItem>
                    ))}
                  </Select>
                </MDBox>
                <MDBox mt={4} mb={1}>
                  <MDButton variant="gradient" color="info" type="submit">
                    Create
                  </MDButton>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default Create;
