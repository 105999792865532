import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import AddIcon from "@mui/icons-material/AddBox";
import IconButton from "@mui/material/IconButton";
import apiService from "../../services/ApiService";
import { toast } from "react-hot-toast";
import { Link } from "react-router-dom";

function Tables() {
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);

  const fetchBusinessUnitsData = async () => {
    try {
      const response = await apiService.get("/api/v1/businessUnits/list");
      if (response.status === 200) {
        const businessUnits = response.data.businessUnits;

        setColumns([
          { Header: "Name", accessor: "name", width: "25%", align: "left" },
          { Header: "Description", accessor: "description", align: "left" },
          { Header: "Legal Entity Number", accessor: "legalEntityNumber", align: "left" },
          { Header: "Code", accessor: "code", align: "left" },
          { Header: "Company", accessor: "company", align: "left" },
          { Header: "Action", accessor: "action", align: "center" },
        ]);

        const formattedRows = businessUnits.map((unit) => ({
          id: unit._id, // Assuming `id` is the unique identifier for each business unit
          name: (
            <MDTypography display="block" variant="button" fontWeight="medium">
              {unit.name}
            </MDTypography>
          ),
          description: (
            <MDTypography variant="caption" color="text" fontWeight="medium">
              {unit.description}
            </MDTypography>
          ),
          legalEntityNumber: (
            <MDTypography variant="caption" color="text" fontWeight="medium">
              {unit.legalEntityNumber}
            </MDTypography>
          ),
          code: (
            <MDTypography variant="caption" color="text" fontWeight="medium">
              {unit.code}
            </MDTypography>
          ),
          company: (
            <MDTypography variant="caption" color="text" fontWeight="medium">
              {unit.company ? unit.company.name : "N/A"} {/* Handle null company */}
            </MDTypography>
          ),
          action: (
            <MDBox display="flex" justifyContent="center">
              <Link to={`/business/edit/${unit._id}`}>
                <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
                  Edit
                </MDTypography>
              </Link>
              <MDBox mx={1} />
              <MDTypography
                component="a"
                variant="caption"
                color="text"
                fontWeight="medium"
                onClick={() => handleDelete(unit._id)}
                sx={{ mx: 1, cursor: "pointer" }} // Add cursor pointer style
              >
                Delete
              </MDTypography>
            </MDBox>
          ),
        }));

        setRows(formattedRows);
      }
    } catch (error) {
      toast.error("Failed to fetch business units data");
    }
  };

  useEffect(() => {
    fetchBusinessUnitsData();
  }, []);

  const handleDelete = async (businessUnitId) => {
    try {
      const response = await apiService.delete(`/api/v1/businessUnits/delete/${businessUnitId}`);
      if (response.status === 200) {
        toast.success("BusinessUnit deleted successfully");
        setRows((prevRows) => prevRows.filter((row) => row.id !== businessUnitId));
      }
    } catch (error) {
      toast.error("Failed to delete business Unit");
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <MDTypography variant="h6" color="white">
                  Business Units
                </MDTypography>
                <Link to={"/business/create"}>
                  <IconButton color="white">
                    <AddIcon />
                  </IconButton>
                </Link>
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  table={{ columns, rows }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default Tables;
