import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText";
import FormControlLabel from "@mui/material/FormControlLabel";
import apiService from "../../services/ApiService";
import { useNavigate } from "react-router-dom";

function Create() {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: "",
    description: "",
    code: "",
    businessUnits: [],
  });

  const [businessUnitOptions, setBusinessUnitOptions] = useState([]);

  useEffect(() => {
    const fetchOptions = async () => {
      try {
        const businessUnitsResponse = await apiService.get("/api/v1/businessUnits/list");

        if (businessUnitsResponse.status === 200) {
          setBusinessUnitOptions(
            businessUnitsResponse.data.businessUnits.map((unit) => ({
              value: unit._id,
              label: unit.name,
            }))
          );
        }
      } catch (error) {
        console.error("Failed to fetch options:", error);
      }
    };

    fetchOptions();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleBusinessUnitChange = (index, field, value) => {
    const updatedBusinessUnits = formData.businessUnits.map((businessUnit, i) => {
      if (i === index) {
        return { ...businessUnit, [field]: value };
      }
      return businessUnit;
    });
    setFormData({ ...formData, businessUnits: updatedBusinessUnits });
  };

  const addBusinessUnit = () => {
    setFormData({
      ...formData,
      businessUnits: [...formData.businessUnits, { businessUnit: "", status: true }],
    });
  };

  const removeBusinessUnit = (index) => {
    setFormData({
      ...formData,
      businessUnits: formData.businessUnits.filter((_, i) => i !== index),
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await apiService.post("/api/v1/departments/create", formData);
      if (response.status === 200) {
        navigate("/departments");
        console.log("Department created successfully:", response.data);
      }
    } catch (error) {
      console.error("Failed to create department:", error);
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <MDTypography variant="h6" color="white">
                  Create Department
                </MDTypography>
              </MDBox>
              <MDBox pt={3} px={2} component="form" onSubmit={handleSubmit}>
                <MDBox mb={2}>
                  <MDInput
                    type="text"
                    label="Department Name"
                    name="name"
                    variant="standard"
                    fullWidth
                    value={formData.name}
                    onChange={handleInputChange}
                  />
                </MDBox>
                <MDBox mb={2}>
                  <MDInput
                    type="text"
                    label="Description"
                    name="description"
                    variant="standard"
                    fullWidth
                    value={formData.description}
                    onChange={handleInputChange}
                  />
                </MDBox>
                <MDBox mb={2}>
                  <MDInput
                    type="text"
                    label="Code"
                    name="code"
                    variant="standard"
                    fullWidth
                    value={formData.code}
                    onChange={handleInputChange}
                  />
                </MDBox>

                {/* Business Units Section */}
                <MDBox mb={2}>
                  <MDTypography variant="h6" mb={1}>
                    Business Units
                  </MDTypography>
                  {formData.businessUnits.map((businessUnit, index) => (
                    <MDBox key={index} display="flex" alignItems="center" mb={1}>
                      <FormControl fullWidth variant="standard" sx={{ mr: 2 }}>
                        <InputLabel id={`business-unit-label-${index}`}>
                          Select Business Unit
                        </InputLabel>
                        <Select
                          labelId={`business-unit-label-${index}`}
                          value={businessUnit.businessUnit}
                          onChange={(e) =>
                            handleBusinessUnitChange(index, "businessUnit", e.target.value)
                          }
                        >
                          {businessUnitOptions.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={businessUnit.status}
                            onChange={(e) =>
                              handleBusinessUnitChange(index, "status", e.target.checked)
                            }
                            color="primary"
                          />
                        }
                        label="Active"
                      />
                      <MDButton
                        variant="text"
                        color="error"
                        onClick={() => removeBusinessUnit(index)}
                      >
                        Remove
                      </MDButton>
                    </MDBox>
                  ))}
                  <MDButton variant="text" color="info" onClick={addBusinessUnit}>
                    Add Business Unit
                  </MDButton>
                </MDBox>

                <MDBox mt={4} mb={1}>
                  <MDButton variant="gradient" color="info" type="submit">
                    Create
                  </MDButton>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default Create;
