import React, { useState, useEffect, useContext } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import apiService from "../../services/ApiService";
import { toast } from "react-hot-toast";
import IconButton from "@mui/material/IconButton";
import { Link } from "react-router-dom";
import AddIcon from "@mui/icons-material/AddBox";
import Switch from "@mui/material/Switch";
import { AuthContext } from "../../context/AuthContext";
import { MenuItem, Select, Checkbox, ListItemText, FormControl, InputLabel } from "@mui/material";

function Users() {
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  const [roles, setRoles] = useState([]);
  const { permission } = useContext(AuthContext);
  const {
    Admin = false,
    HrManager = false,
    HRBP = false,
    ItAdmin = false,
    Employee = false,
  } = permission;

  // Fetch Roles
  useEffect(() => {
    const fetchRoles = async () => {
      try {
        const rolesResponse = await apiService.get("/api/v1/roles/list");
        if (rolesResponse.status === 200) {
          setRoles(rolesResponse.data.roles);
        }
      } catch (error) {
        toast.error("Failed to fetch roles");
      }
    };

    fetchRoles();
  }, []); // Empty dependency array means this effect runs once on mount

  // Fetch Users
  useEffect(() => {
    const fetchUsersData = async () => {
      try {
        const usersResponse = await apiService.get("/api/v1/users/list");
        if (usersResponse.status === 200) {
          const users = usersResponse.data.users;
          setColumns([
            { Header: "First Name", accessor: "firstName", align: "left" },
            { Header: "Second Name", accessor: "secondName", align: "left" },
            { Header: "Employee No.", accessor: "employeeNo", align: "left" },
            { Header: "Company", accessor: "company", align: "left" },
            { Header: "Business Unit", accessor: "businessUnit", align: "left" },
            { Header: "Department", accessor: "department", align: "left" },
            { Header: "Role", accessor: "role", align: "left" },
            { Header: "Approved", accessor: "isApproved", align: "center" },
            { Header: "Action", accessor: "action", align: "center" },
          ]);

          const formattedRows = users.map((user) => ({
            id: user._id,
            firstName: (
              <MDTypography display="block" variant="button" fontWeight="medium">
                {user.firstName}
              </MDTypography>
            ),
            secondName: (
              <MDTypography variant="caption" color="text" fontWeight="medium">
                {user.secondName}
              </MDTypography>
            ),
            employeeNo: (
              <MDTypography variant="caption" color="text" fontWeight="medium">
                {user.employeeNo}
              </MDTypography>
            ),
            company: (
              <MDTypography variant="caption" color="text" fontWeight="medium">
                {user.company?.name || "N/A"}
              </MDTypography>
            ),
            businessUnit: (
              <MDTypography variant="caption" color="text" fontWeight="medium">
                {user.businessUnit?.name || "N/A"}
              </MDTypography>
            ),
            department: (
              <MDTypography variant="caption" color="text" fontWeight="medium">
                {user.department?.name || "N/A"}
              </MDTypography>
            ),
            role: (
              <FormControl variant="outlined" size="small">
                {/* <InputLabel>Roles</InputLabel> */}
                <Select
                  multiple
                  value={user.roles.map((role) => role._id)}
                  onChange={(e) => handleRoleChange(user._id, e.target.value)}
                  renderValue={(selected) => (
                    <div>
                      {roles
                        .filter((role) => selected.includes(role._id))
                        .map((role) => role.name)
                        .join(", ")}
                    </div>
                  )}
                >
                  {roles.map((role) => (
                    <MenuItem key={role._id} value={role._id}>
                      <Checkbox checked={user.roles.some((r) => r._id === role._id)} />
                      <ListItemText primary={role.name} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            ),
            isApproved: (
              <Switch
                checked={user.isApproved}
                onChange={() => handleToggleApproval(user._id, !user.isApproved)}
                color="primary"
              />
            ),
            action: (
              <MDBox display="flex" alignItems="center" justifyContent="center">
                <Link to={`/users/edit/${user._id}`}>
                  <MDTypography
                    component="a"
                    variant="caption"
                    color="text"
                    fontWeight="medium"
                    sx={{ mx: 1 }}
                  >
                    Edit
                  </MDTypography>
                </Link>
                <MDTypography
                  component="a"
                  variant="caption"
                  color="text"
                  fontWeight="medium"
                  onClick={() => handleDelete(user._id)}
                  sx={{ mx: 1, cursor: "pointer" }}
                >
                  Delete
                </MDTypography>
              </MDBox>
            ),
          }));

          setRows(formattedRows);
        }
      } catch (error) {
        toast.error("Failed to fetch users");
      }
    };

    fetchUsersData();
  }, [roles]); // This effect runs when `roles` changes, ensuring that roles are fetched first

  // Handle role change
  const handleRoleChange = async (userId, newRoles) => {
    console.log("newRoles", newRoles);
    try {
      const response = await apiService.put(`/api/v1/users/update/${userId}`, {
        roles: newRoles,
      });
      toast.success("User roles updated successfully");
      console.log("rolesResponse", response);
      setRows((prevRows) =>
        prevRows.map((row) => {
          if (row.id === userId) {
            return {
              ...row,
              role: (
                <FormControl variant="outlined" size="small">
                  {/* <InputLabel>Roles</InputLabel> */}
                  <Select
                    multiple
                    value={newRoles}
                    onChange={(e) => handleRoleChange(userId, e.target.value)}
                    renderValue={(selected) => (
                      <div>
                        {roles
                          .filter((role) => selected.includes(role._id))
                          .map((role) => role.name)
                          .join(", ")}
                      </div>
                    )}
                  >
                    {roles.map((role) => (
                      <MenuItem key={role._id} value={role._id}>
                        <Checkbox checked={newRoles.includes(role._id)} />
                        <ListItemText primary={role.name} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              ),
            };
          }
          return row;
        })
      );
    } catch (error) {
      toast.error("Failed to update user roles");
    }
  };

  // Handle approval toggle
  const handleToggleApproval = async (userId, newStatus) => {
    try {
      const response = await apiService.put(`/api/v1/users/update/${userId}`, {
        isApproved: newStatus,
      });
      if (response.status === 200) {
        toast.success("User approval status updated successfully");
        setRows((prevRows) =>
          prevRows.map((row) => {
            if (row.id === userId) {
              return {
                ...row,
                isApproved: (
                  <Switch
                    checked={newStatus}
                    onChange={() => handleToggleApproval(userId, !newStatus)}
                    color="primary"
                  />
                ),
              };
            }
            return row;
          })
        );
      }
    } catch (error) {
      toast.error("Failed to update user approval status");
    }
  };

  // Handle delete
  const handleDelete = async (userId) => {
    try {
      const response = await apiService.delete(`/api/v1/users/delete/${userId}`);
      if (response.status === 200) {
        toast.success("User deleted successfully");
        setRows((prevRows) => prevRows.filter((row) => row.id !== userId));
      }
    } catch (error) {
      toast.error("Failed to delete user");
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <MDTypography variant="h6" color="white">
                  Users
                </MDTypography>
                <Link to={"/users/create"}>
                  {!Admin && (
                    <IconButton color="white">
                      <AddIcon />
                    </IconButton>
                  )}
                </Link>
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  table={{ columns, rows }}
                  isSorted={false}
                  // canSearch
                  entriesPerPage={false}
                  showTotalEntries={false}
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default Users;
