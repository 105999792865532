import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import AddIcon from "@mui/icons-material/AddBox";
import IconButton from "@mui/material/IconButton";
import apiService from "../../services/ApiService";
import { toast } from "react-hot-toast";
import { Link } from "react-router-dom";

function Tables() {
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);

  useEffect(() => {
    const fetchDepartmentsData = async () => {
      try {
        const response = await apiService.get("/api/v1/departments/list");
        if (response.status === 200) {
          const departments = response.data.departments;
          setColumns([
            { Header: "Name", accessor: "name", width: "15%", align: "left" },
            { Header: "Description", accessor: "description", align: "left" },
            { Header: "Code", accessor: "code", align: "left" },
            { Header: "Business Units", accessor: "businessUnits", align: "left" },
            { Header: "Action", accessor: "action", align: "center" },
          ]);

          const formattedRows = departments.map((department) => ({
            id: department._id,
            name: (
              <MDTypography display="block" variant="button" fontWeight="medium">
                {department.name}
              </MDTypography>
            ),
            description: (
              <MDTypography variant="caption" color="text" fontWeight="medium">
                {department.description}
              </MDTypography>
            ),
            code: (
              <MDTypography variant="caption" color="text" fontWeight="medium">
                {department.code}
              </MDTypography>
            ),
            businessUnits: (
              <MDTypography variant="caption" color="text" fontWeight="medium">
                {department.businessUnits
                  .map((b) => `${b.businessUnit?.name} (${b.status ? "Active" : "Inactive"})`)
                  .join(", ")}
              </MDTypography>
            ),
            action: (
              <MDBox display="flex" justifyContent="center">
                <Link to={`/departments/edit/${department._id}`}>
                  <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
                    Edit
                  </MDTypography>
                </Link>
                <MDBox mx={1} />
                <MDTypography
                  component="a"
                  variant="caption"
                  color="text"
                  fontWeight="medium"
                  onClick={() => handleDelete(department._id)}
                  sx={{ mx: 1, cursor: "pointer" }}
                >
                  Delete
                </MDTypography>
              </MDBox>
            ),
          }));

          setRows(formattedRows);
        }
      } catch (error) {
        toast.error("Failed to fetch departments data");
      }
    };

    fetchDepartmentsData();
  }, []);

  const handleDelete = async (departmentId) => {
    try {
      const response = await apiService.delete(`/api/v1/departments/delete/${departmentId}`);
      if (response.status === 200) {
        toast.success("Department deleted successfully");
        setRows((prevRows) => prevRows.filter((row) => row.id !== departmentId));
      }
    } catch (error) {
      toast.error("Failed to delete department");
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <MDTypography variant="h6" color="white">
                  Departments
                </MDTypography>
                <Link to={"/departments/create"}>
                  <IconButton color="white">
                    <AddIcon />
                  </IconButton>
                </Link>
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  table={{ columns, rows }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default Tables;
