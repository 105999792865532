import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import apiService from "../../services/ApiService";
import { toast } from "react-hot-toast";

function AttendanceLogs() {
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  const [noRecords, setNoRecords] = useState(false); // State to track no records

  useEffect(() => {
    const fetchAttendanceLogs = async () => {
      try {
        const response = await apiService.get("/api/v1/users/attendanceLogs");
        if (response.status === 200) {
          const logs = response.data.data;

          if (logs.length === 0) {
            setNoRecords(true); // Set no records if logs are empty
            return;
          }

          // Set the columns for the attendance logs table
          setColumns([
            { Header: "User Name", accessor: "userName", width: "20%", align: "left" },
            { Header: "Email", accessor: "email", width: "20%", align: "left" },
            { Header: "Date", accessor: "date", width: "15%", align: "center" },
            { Header: "Check In Time", accessor: "checkInTime", width: "15%", align: "center" },
            { Header: "Check Out Time", accessor: "checkOutTime", width: "15%", align: "center" },
          ]);

          // Format the rows for the table
          const formattedRows = logs.map((log) => ({
            userName: (
              <MDTypography display="block" variant="button" fontWeight="medium">
                {log.user.name}
              </MDTypography>
            ),
            email: (
              <MDTypography variant="caption" color="text" fontWeight="medium">
                {log.user.email}
              </MDTypography>
            ),
            date: (
              <MDTypography variant="caption" color="text" fontWeight="medium">
                {log.attendance[0]?.date || "N/A"}
              </MDTypography>
            ),
            checkInTime: (
              <MDTypography variant="caption" color="text" fontWeight="medium">
                {log.attendance[0]?.checkInTime || "N/A"}
              </MDTypography>
            ),
            checkOutTime: (
              <MDTypography variant="caption" color="text" fontWeight="medium">
                {log.attendance[0]?.checkOutTime || "N/A"}
              </MDTypography>
            ),
          }));

          setRows(formattedRows);
        }
      } catch (error) {
        toast.error("Failed to fetch attendance logs");
      }
    };

    fetchAttendanceLogs();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Attendance Logs
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                {noRecords ? ( // Conditionally render message if no records are found
                  <MDTypography variant="h6" color="text" align="center" my={2}>
                    No attendance logs found.
                  </MDTypography>
                ) : (
                  <DataTable
                    table={{ columns, rows }}
                    isSorted={false}
                    entriesPerPage={false}
                    showTotalEntries={false}
                    noEndBorder
                  />
                )}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default AttendanceLogs;
