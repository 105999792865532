import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText";
import apiService from "../../services/ApiService";
import { useNavigate, useParams } from "react-router-dom";

function Edit() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: "",
    description: "",
    code: "",
    businessUnits: [],
  });
  const [businessUnitOptions, setBusinessUnitOptions] = useState([]);
  console.log(formData);
  // Fetch department and business units data on component mount
  useEffect(() => {
    const fetchOptions = async () => {
      try {
        // Fetch business unit options
        const response = await apiService.get("/api/v1/businessUnits/list");
        if (response.status === 200) {
          setBusinessUnitOptions(
            response.data.businessUnits.map((unit) => ({
              value: unit._id,
              label: unit.name,
            }))
          );
        }

        // Fetch the department data by ID
        const departmentResponse = await apiService.get(`/api/v1/departments/detail/${id}`);
        if (departmentResponse.status === 200) {
          const department = departmentResponse.data.department;
          console.log("department", department);
          setFormData({
            name: department.name,
            description: department.description,
            code: department.code,
            businessUnits: department.businessUnits.map((bu) => bu.businessUnit),
          });
        }
      } catch (error) {
        console.error("Failed to fetch data:", error);
      }
    };

    fetchOptions();
  }, [id]);

  // Handle input change
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Handle business unit selection
  const handleBusinessUnitChange = (event) => {
    setFormData({
      ...formData,
      businessUnits: event.target.value,
    });
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Submit the form data to the API
      const response = await apiService.put(`/api/v1/departments/update/${id}`, {
        name: formData.name,
        description: formData.description,
        code: formData.code,
        businessUnits: formData.businessUnits,
      });
      if (response.status === 200) {
        navigate("/departments");
        console.log("Department updated successfully");
        // Handle successful update (e.g., redirect or show a success message)
      }
    } catch (error) {
      console.error("Failed to update department:", error);
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <MDTypography variant="h6" color="white">
                  Edit Department
                </MDTypography>
              </MDBox>
              <MDBox pt={3} px={2} component="form" onSubmit={handleSubmit}>
                <MDBox mb={2}>
                  <MDInput
                    type="text"
                    label="Department Name"
                    name="name"
                    variant="standard"
                    fullWidth
                    value={formData.name}
                    onChange={handleInputChange}
                  />
                </MDBox>
                <MDBox mb={2}>
                  <MDInput
                    type="text"
                    label="Description"
                    name="description"
                    variant="standard"
                    fullWidth
                    value={formData.description}
                    onChange={handleInputChange}
                  />
                </MDBox>
                <MDBox mb={2}>
                  <MDInput
                    type="text"
                    label="Code"
                    name="code"
                    variant="standard"
                    fullWidth
                    value={formData.code}
                    onChange={handleInputChange}
                  />
                </MDBox>
                <MDBox mb={2}>
                  <FormControl fullWidth>
                    <InputLabel id="business-units-label">Select Business Units</InputLabel>
                    <Select
                      labelId="business-units-label"
                      multiple
                      variant="standard"
                      value={formData.businessUnits}
                      onChange={handleBusinessUnitChange}
                      renderValue={(selected) =>
                        selected
                          .map(
                            (id) => businessUnitOptions.find((option) => option.value === id)?.label
                          )
                          .join(", ")
                      }
                    >
                      {businessUnitOptions.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          <Checkbox checked={formData.businessUnits.includes(option.value)} />
                          <ListItemText primary={option.label} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </MDBox>
                <MDBox mt={4} mb={1}>
                  <MDButton variant="gradient" color="info" type="submit">
                    Save Changes
                  </MDButton>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default Edit;
